.b-input-v4
  font-weight: 500
  position: relative
  width: 100%

  label
    background: #fff
    color: var(--gray-500)
    overflow: hidden
    position: absolute
    text-overflow: ellipsis
    top: 0
    transition: all var(--transition-duration)
    user-select: none
    white-space: nowrap
    z-index: 1

    +lte_ipad
      font-size: rem4(13px)
      left: rem4(12px)
      line-height: rem4(16px)
      padding: 0 rem4(4px)
      max-width: calc(100% - #{rem4(24px)})
      top: rem4(-8px)

    +gte_laptop
      font-size: 13px
      left: 12px
      line-height: 16px
      max-width: calc(100% - 24px)
      padding: 0 4px
      top: -8px

  +lte_ipad
    --input-font-size: #{rem4(15px)}
    --input-line-height: #{rem4(22px)}
    --input-padding: #{rem4(16px)}

  +gte_laptop
    --input-font-size: 15px
    --input-line-height: 22px
    --input-padding: 16px

  input,
  textarea,
  .custom-select-placeholder
    --input-color: var(--gray-900)
    --border-color: var(--gray-300)
    --background-color: #fff
    border: 1px solid var(--border-color)
    background-color: var(--background-color)
    color: var(--input-color)
    font-size: var(--input-font-size)
    line-height: var(--input-line-height)
    padding: var(--input-padding)
    display: flex
    outline: none
    position: relative
    transition: color var(--transition-duration), border-color var(--transition-duration), background-color var(--transition-duration)
    width: 100%

    +lte_ipad
      border-radius: rem4(12px)

    +gte_laptop
      border-radius: 12px

    +placeholder
      transition: opacity ease-in-out var(--transition-duration)
      opacity: 0

    &:empty
      +placeholder
        color: var(--gray-400)

      &:placeholder-shown:not(:focus) + label
        background: transparent
        color: var(--gray-400)
        font-size: var(--input-font-size)
        left: 1px
        line-height: var(--input-line-height)
        padding: var(--input-padding)
        top: 1px

      &:focus
        +placeholder
          opacity: 1

    &:hover
      --border-color: var(--gray-400)

    &.is-focused,
    &:focus
      --border-color: var(--gray-900)

      & + label
        color: var(--gray-900)

    &:disabled
      --border-color: var(--gray-300)
      --input-color: var(--gray-400)
      --background: var(--gray-25)

      & + label
        color: var(--gray-400)

  &.field_with_errors
    input,
    textarea,
    .custom-select-placeholder
      --border-color: #e14242

    label
      color: #e14242

  .custom-select-placeholder
    display: flex
    justify-content: space-between
    user-select: none

    +lte_ipad
      gap: rem4(16px)

    +gte_laptop
      gap: 16px

    &:after
      +font_icon('\E82D')
      color: var(--border-color)
      transition: transform var(--transition-duration)

      +lte_ipad
        font-size: rem4(19px)

      +gte_laptop
        font-size: 19px

    &.is-focused
      &:after
        transform: rotate(180deg)

      & + .custom-select-dropdown
        opacity: 1
        transition: opacity var(--transition-duration) ease
        z-index: 1

    .placeholder-inner
      overflow: hidden
      pointer-events: none
      text-overflow: ellipsis
      user-select: none
      white-space: nowrap

  .custom-select-dropdown
    background-color: var(--input-background-color-focus)
    border-radius: var(--input-border-radius)
    border: 1px solid var(--input-border-color)
    box-shadow: 0px rem(4px) rem(12px) 0px rgba(176, 183, 204, 0.12)
    margin-top: rem(4px)
    opacity: 0
    overflow: scroll
    padding: rem(4px) rem(3px)
    position: absolute
    transition: none
    width: 100%
    z-index: -1

    +lte_ipad
      max-height: rem(310px)

    +gte_laptop
      max-height: 410px

    .v4 &
      box-shadow: none
      padding: var(--spacing-md)

      +lte_ipad
        margin-top: rem4(4px)

      +gte_laptop
        margin-top: 4px

    .option
      border-radius: var(--input-border-radius)
      cursor: default !important
      line-height: 1.3
      transition: background-color var(--transition-duration) ease
      user-select: none

      &.is-current
        color: var(--primary-color)
        font-weight: 700

      &:hover
        +gte_laptop
          background-color: rgba($primary, 0.10)

      &:active
        background-color: rgba($primary, 0.30)

      +lte_ipad
        font-size: rem4(15px)
        line-height: rem4(40px)
        padding: 0px rem4(12px)

      +gte_laptop
        font-size: 15px
        line-height: 40px
        padding: 0px 12px

        &:hover
          background-color: var(--gray-100)

      &:active
        background-color: var(--gray-50)

  .hint,
  .error-message
    font-weight: 500

    +lte_ipad
      font-size: rem4(11px)
      line-height: rem4(14px)
      margin-top: rem4(8px)

    +gte_laptop
      font-size: 11px
      line-height: 14px
      margin-top: 8px

  .hint
    color: var(--gray-500)

  .error-message
    color: #e14242
