.b-form
  // label
  //   color: var(--text-base)
  //   display: block
  //   font-size: rem(12px)
  //   margin: 0 0 rem(4px) rem(6px)
  //
  //   abbr
  //     display: none

  input[type='submit']
    +button_red
    margin-top: rem(12px)
    padding: 0 rem(16px)

    & + .error-message,
    & + .hint
      margin-top: rem(15px)

  .hint
    color: var(--text-secondary)
    display: block
    font-size: rem(12px)
    margin: rem(6px) 0 0

  .b-input .error-message,
  .form-error-message
    color: var(--primary-color-active)
    display: block
    font-size: rem(12px)
    font-weight: 400
    line-height: rem(14px)
    margin-top: rem(5px)
    +text-align_ltr(left)

  abbr
    display: none
