.b-modal
  .modal-shade
    background: #000
    height: 100%
    left: 0
    position: fixed
    top: 0
    transition: opacity var(--transition-duration)
    width: 100%
    z-index: $z-index-modal - 1

    +gte_laptop
      &:hover + .inner > .close
        opacity: 1

  &:not(.v4) .modal-shade
    opacity: 0.4

  &.v4
    &:not(.is-active)
      .modal-shade
        opacity: 0
        pointer-events: none

      .modal-inner
        display: none

    &.is-active
      .modal-shade
        opacity: 0.4

      .modal-inner
        display: block

  & > .modal-inner
    background: #fff
    position: fixed
    top: 50%
    z-index: $z-index-modal
    padding-left: var(--modal-padding-x)
    padding-right: var(--modal-padding-x)

    +lte_ipad
      --modal-padding-x: #{rem4(24px)}
      border-radius: rem4(16px)
      left: var(--spacing-md)
      padding-bottom: rem4(32px)
      padding-top: rem4(52px) 
      right: var(--spacing-md)
      transform: translateY(-50%)

    +gte_laptop
      --modal-padding-x: 56px
      border-radius: 26px
      left: 50%
      padding-bottom: 52px
      padding-top: 52px
      transform: translateX(-50%) translateY(-50%)
      width: 540px

    .modal-close,
    .modal-back
      align-items: center
      color: var(--gray-400)
      cursor: pointer
      display: flex
      justify-content: center
      position: absolute
      transition: color var(--transition-duration)
      z-index: 2

      +lte_ipad
        height: rem4(30px)
        top: rem4(9px)

      +gte_laptop
        height: 30px
        top: 18px

        &:hover
          color: var(--gray-500)

      &:active
        color: var(--gray-600)

      &:before
        +lte_ipad
          font-size: rem4(20px)

        +gte_laptop
          font-size: 20px

    .modal-close:not(:has(svg))
      &:before
        +font-icon('\E82B')

      +lte_ipad
        right: rem4(15px)
        width: rem4(30px)

      +gte_laptop
        right: 18px
        width: 30px

    .modal-back
      &:before
        +font-icon('\E82C')

      +lte_ipad
        +p4
        gap: rem4(4px)
        left: rem4(15px)

      +gte_laptop
        +p3
        gap: 4px
        left: 18px
        letter-spacing: 0.16px

    header,
    .modal-submit-note,
    footer
      +lte_ipad
        padding: 0 rem4(16px)

      +gte_laptop
        padding: 0 16px

    header
      display: flex
      flex-direction: column

      +lte_ipad
        gap: var(--spacing-md)
        margin-bottom: rem4(32px)

      +gte_laptop
        gap: 12px
        margin-bottom: 32px

      &.modal-centered
        text-align: center

      .modal-title
        +lte_ipad
          +p1(700)

        +gte_laptop
          color: #222
          font-size: 24px
          font-weight: 700
          letter-spacing: -0.48px
          line-height: 28px

      .modal-note
        color: var(--gray-500)

        +lte_ipad
          +p4

        +gte_laptop
          +p2

    footer,
    .modal-submit-note
      +lte_ipad
        font-size: rem4(11px)
        line-height: rem4(14px)

      +gte_laptop
        +p5

    footer
      color: var(--gray-500)

      +lte_ipad
        margin-top: rem4(32px)

      +gte_laptop
        margin-top: 32px

    input[type='submit']
      +button_v4('primary', 'xl')
      width: 100%

    .b-input
      +lte_ipad
        margin-bottom: rem4(16px)

      +gte_laptop
        margin-bottom: 16px
