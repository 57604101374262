.p-storages-show .storages-v4-show,
.p-boxes-show .boxes-v4-show
  article
    padding-bottom: 0

    &:not(:last-child):not(.slide_1)
      +lte_ipad
        padding-bottom: rem4(64px)

      +gte_laptop
        padding-bottom: 120px

    h1,
    h2
      +lte_ipad
        text-align: left !important
        width: 100%

  article.slide_1
    gap: 0

    header
      +lte_ipad
        margin-bottom: rem4(24px)

      +gte_laptop
        margin-bottom: 24px

      .is-soon
        +p5
        background: var(--gray-100)
        border-radius: 100px
        color: #000
        display: flex
        white-space: nowrap
        align-self: flex-start

        +lte_ipad
          gap: rem4(6px)
          padding: rem4(4px) rem4(8px)

        +gte_laptop
          gap: 6px
          padding: 4px 8px

        &:before
          +font-icon('\E828')
          +lte_ipad
            font-size: rem4(15px)

          +gte_laptop
            font-size: 15px

    .photos
      display: flex

      +lte_ipad
        order: -1
        margin-bottom: rem4(24px)

      +gte_laptop
        gap: 24px

        &:not(:last-child)
          margin-bottom: 40px

      .photo
        display: none
        position: relative

        +lte_ipad
          &:nth-child(1)
            display: inline-flex
            flex-basis: 100%

        +gte_laptop
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3)
            display: inline-flex

          &:nth-child(1) 
            flex-basis: 50%

          &:nth-child(2),
          &:nth-child(3)
            flex-basis: 24%

      img
        background: var(--gray-100)
        display: flex
        width: 100%

        +lte_ipad
          border-radius: rem4(24px)
          height: rem4(234px)

        +gte_laptop
          object-fit: cover

        +laptop
          // 0.75 -> 960 / 1280
          border-radius: scale-laptop(0.75 * 24px , 24px)
          height: scale-laptop(0.75 * 312px, 312px)

        +gte_desktop
          border-radius: 24px
          height: 312px

    .photo-tags
      +p5(500)
      bottom: var(--tags-offset)
      display: flex
      left: var(--tags-offset)
      position: absolute

      +lte_ipad
        --tags-offset: #{rem4(12px)}
        gap: rem4(8px)

      +gte_laptop
        --tags-offset: 16px
        gap: 8px

      .star
        +lte_ipad
          --star-size: #{rem4(16px)}
        +gte_laptop
          --star-size: 16px
        color: var(--primary-color)
        height: var(--star-size)
        width: var(--star-size)

        &:before
          +font-icon('\E829')
          font-size: var(--star-size)

      &.mobile-counter
        +lte_ipad
          left: auto
          right: var(--tags-offset)

        +gte_laptop
          display: none

        .photo-tag
          backdrop-filter: blur(8px)
          background: rgba(38, 39, 43, 0.30)
          border: 1px solid var(--gray-400)
          color: var(--gray-300)

          span
            color: #fff

      &.see-all-photos
        +lte_ipad
          display: none

        +gte_laptop
          left: auto
          right: var(--tags-offset)

        .photo-tag
          gap: 4px
          background: var(--gray-100)

          &:before
            background-image: url('../images/pages/storages-v4/show/see_all_photos.svg')
            background-position: center
            background-repeat: no-repeat
            background-size: contain
            content: ''
            display: inline-block

            +gte_ipad
              height: 16px
              width: 16px

    .photo-tag
      align-items: center
      background: #fff
      color: var(--text-base)
      display: flex

      +lte_ipad
        border-radius: rem4(23px)
        height: rem4(23px)
        padding: 0 rem4(8px)

      +gte_laptop
        border-radius: 24px
        height: 24px
        padding: 0 8px

      &.discount
        background: #08cc33
        color: #fff

    .features-note-container
      display: flex

      +lte_ipad
        flex-direction: column
        gap: rem4(16px)

      +gte_laptop
        gap: 40px

    .features-wrappper
      +lte_ipad
        padding-bottom: rem4(16px)
        overflow-x: scroll

      +gte_laptop
        flex-grow: 1

    .warning-note
      +p4
      align-items: center
      align-self: flex-start
      background: rgba(255, 79, 0, 0.05)
      display: flex

      +lte_ipad
        border-radius: rem4(12px)
        gap: rem4(16px)
        padding: rem4(16px)

      +gte_laptop
        border-radius: 12px
        gap: 16px
        padding: 16px
        width: 320px

      &:before
        content: ''
        background-image: url('../images/pages/storages-v4/show/warning_note.svg')
        background-position: center
        background-repeat: no-repeat
        background-size: contain
        flex-shrink: 0

        +lte_ipad
          width: rem4(24px)
          height: rem4(24px)

        +gte_laptop
          width: 24px
          height: 24px

.p-storages-show .storages-v4-show
  article.slide_3
    +container

    .b-map
      border-radius: rem4(24px)
      box-shadow: -4px 8px 20px 0px rgba(0, 0, 0, 0.10)
      overflow: hidden

  article.slide_4
    .tour-3d
      iframe
        +lte_ipad
          border-radius: rem4(24px)
          box-shadow: -4px 8px 20px 0px rgba(0, 0, 0, 0.10)
          overflow: hidden

        +gte_ipad
          border-radius: 32px
